<template>
    <div v-if="isAdmin">
        <h2>Управление слоями</h2>
        <div v-if="!isSaved">
            <div class="list-group">
                <button type="button" class="list-group-item list-group-item-action" v-for="(vectorLayer) in vectorLayers" :key="vectorLayer.UserName" @click="setActiveUser(vectorLayer.UserName)">
                    {{vectorLayer.UserName}}
                </button>
            </div>
            <div class="container-fluid">
                <table class="table">
                    <thead class="thead-dark">
                        <tr>
                            <!--
                            string groupname
                            string levelicon
                            string levelname
                            string searchname
                            bool visible
                            object belongsFilterOptions
                            -->
                            <th scope="col" class="col-1">#</th>
                            <th scope="col" class="col-1">Visible</th>
                            <th scope="col" class="col-2">Group</th>
                            <th scope="col" class="col-2">Name</th>
                            <th scope="col" class="col-2">SearchName</th>
                            <th scope="col" class="col-1">Icon</th>
                            <th scope="col" class="col-2">BelongsFilter</th>
                            <th scope="col" class="col-1">Remove</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(vectorLayer, index) in currentUserVectorLayer" :key="vectorLayer.UserName">
                            <td class="col-1" scope="row">{{index+1}}</td>
                            <td class="col-1"><input class="form-control" type="checkbox" v-model="vectorLayer['visible']" /></td>
                            <td class="col-2"><input class="form-control" type="text" v-model="vectorLayer['groupname']" /></td>
                            <td class="col-2"><input class="form-control" type="text" v-model="vectorLayer['levelname']" /></td>
                            <td class="col-2"><input class="form-control" type="text" v-model="vectorLayer['searchname']" /></td>
                            <td class="col-1"><input class="form-control" type="text" v-model="vectorLayer['levelicon']" /></td>
                            <td class="col-2"><input class="form-control" type="text" v-model="vectorLayer['belongsFilterOptions']" /></td>
                            <td class="col-1"><button @click="removeItem(vectorLayer)" class="btn btn-dark disabled" aria-hidden="true"><span class="fa fa-recycle"></span></button></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <button class="btn btn-dark" @click="saveBtn">Сохранить</button>
            <button class="btn btn-dark ml-1" @click="addNewLayer">Добавить слой</button>
        </div>
        <div v-else>
            <SavedMessage />
        </div>
    </div>
    <div v-else>
        <ErrorMessage messagevalue="Нужны права администратора"></ErrorMessage>
    </div>

</template>

<script>
    import {  onMounted, computed,ref } from 'vue';
    import { useStore } from 'vuex';
    import ErrorMessage from '../Shared/ErrorMessage.vue';
    import SavedMessage from '../Shared/SavedMessage.vue';
    import { loginservice } from '../../services/loginservice';
    import { vectorlayerservice } from '../../services/vectorlayerservice';

    export default {
        name: 'Users Management',
        setup() {
            const store = useStore();
            const isAdmin = computed(() => (store.state.isAdmin));
            const isSaved = ref(false);
            const vectorLayers = ref([]);
            const currentUserVectorLayer = ref({});
            const currentUserName = ref('unknown');
            let vectorLayerServise = null;
            onMounted(async () => {
                let logonResult = await loginservice.checkLogin();
                //Check Auth
                if (logonResult.result === true) {
                 
                    let options = {
                        jwt: logonResult.credits.jwt,
                        username: logonResult.credits.username
                    }
                    vectorLayerServise = new vectorlayerservice(options);  
                    
                    vectorLayers.value = await vectorLayerServise.getAllLevels();
                    
                    //MAP
                    for (let item of vectorLayers.value) {
                        for (let belongFilter of item.Levels) {
                            belongFilter.belongsFilterOptions = JSON.stringify(belongFilter.belongsFilterOptions);
                        }
                    }

                    currentUserName.value = vectorLayers.value[0].UserName;
                    currentUserVectorLayer.value = vectorLayers.value[0].Levels;
                }
            });



            const setActiveUser = (userName) => {
                let userindex = vectorLayers.value.findIndex(o => o.UserName == userName);
                if (userindex > -1) {
                    currentUserName.value = userName;
                    currentUserVectorLayer.value = vectorLayers.value[userindex].Levels;
                }
                else {
                    console.log("error when setActiceUser: " + userName);
                }
            }

            const addNewLayer = async () => {
                try {
                    let newLayer = vectorLayerServise.addLevel();
                    currentUserVectorLayer.value.push(newLayer);
                }
                catch {
                    console.log("addNewLayer error");
                }
            }

            const saveBtn = async () => {
                //unMAP
                for (let item of vectorLayers.value) {
                    for (let belongFilter of item.Levels) {
                        belongFilter.belongsFilterOptions = JSON.parse(belongFilter.belongsFilterOptions);
                    }
                }
                let saveResult = await vectorLayerServise.setAllLevels(vectorLayers.value);
                if (saveResult) {
                    isSaved.value = true;
                }
                else {
                    console.log("and error occured when save users list");
                }                
            }

            const removeItem = async (vectorLayer) => {
                let vectorLayerIndex = currentUserVectorLayer.value.findIndex(o => o.levelname == vectorLayer.levelname && o.groupname == vectorLayer.groupname);
                if (vectorLayerIndex > -1) {
                    currentUserVectorLayer.value.splice(vectorLayerIndex, 1);
                    return;
                }
                console.log("Error! Unable to remove user with name: " + vectorLayer.levelname);
           }

            return {
                isAdmin,
                isSaved,
                saveBtn,
                addNewLayer,
                removeItem,
                vectorLayers,
                currentUserVectorLayer,
                currentUserName,
                setActiveUser
            }
        },

        components: {
            ErrorMessage,
            SavedMessage
        }
    }
</script>