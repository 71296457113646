import 'bootstrap/dist/css/bootstrap.css'

import 'leaflet/dist/leaflet.css'
import 'leaflet-panel-layers/dist/leaflet-panel-layers.min.css'

import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import jQuery from 'jquery';
import bootstrap from 'bootstrap';

createApp(App).use(store).use(router).mount('#app')
