import { useStore } from 'vuex';
import { fetchservice } from './fetchservice';
import { messagesservice } from './messagesservice';


export class loginservice {
   
    static loginUrl = '/api/login/user';
    static logoutUrl = '/api/login/logout';
    static getAllUsersUrl = '/api/login/getallusers';
    static setAllUsersUrl = '/api/login/setallusers';

    static defaultPasswordHash = 'RfEG701RYeeqOM9sZmYH8ldItso=';
    static unknownUser = {
        username: "unknown",
        userrole: "unknown",
        authenticated: false,
        isAdmin: false,
        jwt: "unknown",
        hasunreadedmessages: false,
        unreadedmessagescount: 0
    };

    static setAllUsers = async (newUsers, jwt) => {
        try {
            let requestBody = JSON.stringify(newUsers);
            let options = { jwt: jwt };
            let response = await (new fetchservice(options)).postFetchResult(loginservice.setAllUsersUrl, requestBody);
            return response;
        }
        catch (error) {
            console.log("baselayersservice service error:" + error); // from creation or business logic
        }
    }

    static getAllUsers = async (jwt) => {
        try {
            let options = { jwt: jwt };
            let response = await (new fetchservice(options)).getFetchResult(loginservice.getAllUsersUrl);
            return response;
        }
        catch (error) {
            console.log("getAllUsers loginservice error:" + error);
        }
    }

    static addUser = () => {
        return {
            UserName: "NewUser",
            UserPassword: loginservice.defaultPasswordHash,//userpass
            Role: "user"
        }
    }



    static logout = async () => {   
        //const store = useStore();
        try {                        
            let response = await fetch(loginservice.logoutUrl, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
            });  
            await response.status;
            //TODO:00+update store when logout?
            const store = useStore();
            await store.dispatch('setAuth', loginservice.unknownUser);
        }
        catch (error) {
            console.log("logout service error:" + error);
        }
    }

    static activeUserName = async () => {
        const response = await fetch(loginservice.loginUrl, {
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include'
        });
        const resultContent = await response.json();
        //Check Auth
        if (resultContent.status === 401) {
            return loginservice.unknownUser.username;
        }
        else {
            return resultContent.UserName;
        }
    }

    static checkLogin = async () => {


        let resultValue = false;
        const store = useStore();

        //loginservice - check already logged in
        if (store.state.authenticated === true) {
            console.log("fast checklogin");
            return {
                result: true,
                credits: {
                    jwt: store.state.jwt,
                    username: store.state.username,
                    userrole: store.state.userrole,
                }
            }
        }

        const response = await fetch(loginservice.loginUrl, {
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include'
        });

        const resultContent = await response.json();
        //Check Auth
        if (resultContent.status === 401) {
            resultValue = false;
            await store.dispatch('setAuth', loginservice.unknownUser);
        }
        else {
            resultValue = true;

            let activeUserName = await loginservice.activeUserName();
            let unreadedmessagescount = await new messagesservice().getUnreadedMessagesCount(activeUserName);
            let hasunreadedmessages = (loginservice.unknownUser.username !== activeUserName) && (unreadedmessagescount > 0);

            let userAuthInfo = {
                authenticated: true,                
                isAdmin: resultContent.Role == "admin",
                username: resultContent.UserName,
                userrole: resultContent.Role,
                jwt: resultContent.jwt,
                hasunreadedmessages: hasunreadedmessages,
                unreadedmessagescount: unreadedmessagescount
            }
            console.log("logged in: " + userAuthInfo.username + "/" + userAuthInfo.userrole);

            await store.dispatch('setAuth', userAuthInfo);
        }

        return {
            result: resultValue,
            credits: {
                jwt: resultContent.jwt,
                username: resultContent.UserName,
                userrole: resultContent.Role
            }
        }
    }
}