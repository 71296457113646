import { fetchservice} from './fetchservice';

export class popupservice extends fetchservice {
	static translationsUrl = "/api/popup/translations";
	static ignorationsUrl = "/api/popup/ignorations";

	//options {
	// jwt - bearer token	
	// }
	constructor(options) {
		super(options);
		this.jwt = options.jwt;
	}

	getTranslations = async () => {
		let result = await this.getFetchResult(popupservice.translationsUrl);
		return result;
    }

	getIgnorations = async () => {
		let result = await this.getFetchResult(popupservice.ignorationsUrl);
		return result;
	}	

	setTranslations = async (translations) => {
		let requestBody = JSON.stringify(translations);
		let postResult = await this.postFetchResult(popupservice.translationsUrl, requestBody);
		return postResult;
    }

	setIgnorations = async (ignorations) => {
		let requestBody = JSON.stringify(ignorations);
		let postResult = await this.postFetchResult(popupservice.ignorationsUrl, requestBody);
		return postResult;
	}
}