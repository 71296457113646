<template>
    <div v-if="isAdmin">
        <h2>Управление пользователями</h2>
        <div v-if="!isSaved">
            <div class="container">
                <table class="table">
                    <thead class="thead-dark">
                        <tr>
    <!--
        UserName
        UserPassword
        Role
    -->
                            <th scope="col" class="col-1">#</th>
                            <th scope="col" class="col-3">Name</th>
                            <th scope="col" class="col-2">Role</th>
                            <th scope="col" class="col-5">Password Hash</th>
                            <th scope="col" class="col-1">Remove</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(user, index) in users" :key="user.UserName">
                            <td class="col-1" scope="row">{{index+1}}</td>
                            <td class="col-3"><input class="form-control" type="text" v-model="user['UserName']" /></td>
                            <td class="col-2"><input class="form-control" type="text" v-model="user['Role']" /></td>
                            <td class="col-5"><input class="form-control" type="text" v-model="user['UserPassword']" /></td>
                            <td class="col-1"><button @click="removeItem(user)" class="btn btn-dark disabled" aria-hidden="true"><span class="fa fa-recycle"></span></button></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <button class="btn btn-dark" @click="saveBtn">Сохранить</button>
            <button class="btn btn-dark ml-1" @click="addNewUser">Добавить пользователя</button>
        </div>
        <div v-else>
            <SavedMessage />
        </div>
    </div>
    <div v-else>
        <ErrorMessage messagevalue="Нужны права администратора"></ErrorMessage>
    </div>

</template>

<script>
    import {  onMounted, computed,ref } from 'vue';
    import { useStore } from 'vuex';
    import ErrorMessage from '../Shared/ErrorMessage.vue';
    import SavedMessage from '../Shared/SavedMessage.vue';
    import { loginservice } from '../../services/loginservice';

    export default {
        name: 'Users Management',
        setup() {
            const store = useStore();
            const isAdmin = computed(() => (store.state.isAdmin));
            const isSaved = ref(false);
            const users = ref([]);
            const jwt = ref('');
            onMounted(async () => {
                let logonResult = await loginservice.checkLogin();
                //Check Auth
                if (logonResult.result === true) {
                    jwt.value = logonResult.credits.jwt;
                    users.value = await loginservice.getAllUsers(jwt.value);
                    if (users.value == "error") {
                        console.log("error when fetching users list");                        
                        return;
                    }
                }
            });


            //TODO:10-2 reset password for user

            const addNewUser = async () => {
                var newUser = loginservice.addUser();
                users.value.push(newUser);
            }

            const saveBtn = async () => {
                let saveResult = await loginservice.setAllUsers(users.value, jwt.value);
                if (saveResult) {
                    isSaved.value = true;
                }
                else {
                    console.log("and error occured when save users list");
                }
            }

            const removeItem = async (itemName) => {
                let index = users.value.indexOf(itemName);
                let userName = itemName.UserName;
                if (index > -1) {
                    users.value.splice(index, 1);
                    return;
                }
                console.log("Error! Unable to remove user with name: " + userName);
            }

            return {
                isAdmin,
                isSaved,
                saveBtn,
                users,
                addNewUser,
                removeItem
            }
        },

        components: {
            ErrorMessage,
            SavedMessage
        }
    }
</script>