<template>
  <nav-menu/>    
  <router-view/>
</template>

<script>
    import NavMenu from './components/NavMenu.vue'

export default {
        name: 'App',        
        components: {
            NavMenu
            
        }
}
</script>

<style>
    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
        margin-top: 0px;
        height:100vh;
    }

    .leaflet-top {
        margin-top: 80px;
    }
</style>
