<template>
    <div id="messages" class="container">
        <h1>Что нового</h1>
        <div v-if="isErrorOrNotLogged" id="errormessage">
            <ErrorMessage :messagevalue="message" :redirect="isredirectonerror"></ErrorMessage>
        </div>

        <div  v-for="(message, index) in messages" :key="index">        
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">
                        {{message.MessageDate}}: {{message.MessageTitle}}
                    </h5>
                    <div class=" justify-content-start">
                        <div v-for="(whatsnewitem, index) in message.MessageBody" :key="index">
                            {{whatsnewitem}}<br />
                        </div>
                    </div>
                </div>
            </div>
            <hr />
        </div>
    </div>
</template>
<script>
    import { onMounted, ref } from 'vue';
    import { loginservice } from '../services/loginservice';
    import { messagesservice } from '../services/messagesservice';
    import ErrorMessage from './Shared/ErrorMessage.vue';
    export default {
        name: "Messages",
        setup() {
            //Login Service
            const isErrorOrNotLogged = ref(false);
            const message = ref('Необходима аутентификация!');
            const isredirectonerror = ref('false');
            const messages = ref([]);
            onMounted(async () => {
                try {
                    let logonResult = await loginservice.checkLogin();
                    //Check Auth
                    if (logonResult.result === true) {
                        message.value = "allok";
                        let credits = logonResult.credits;
                        //get messages from backend
                        let messagesResult = await new messagesservice(credits).getMessages();
                        messages.value = messagesResult;
                        console.log(messages.value);
                    }
                    else {
                        isErrorOrNotLogged.value = true;
                        isredirectonerror.value = false;
                    }
                } catch (e) {
                    message.value = "catch on Messages.vue: " + e;
                    isredirectonerror.value = false;
                    isErrorOrNotLogged.value = true;
                }
            });
            return {
                isErrorOrNotLogged,
                isredirectonerror,
                message,
                messages
            }
        },
        components: {
            ErrorMessage
        },
    }
</script>
