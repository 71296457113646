import * as L from 'leaflet';
export class baselayersservice {

	static defaultUrl = "/api/baselayers";	

	//options {
	// jwt - bearer token
	// url - api url
	// }
	constructor(options) {
		this.jwt = options.jwt;
		this.url = (options.url === undefined) ? baselayersservice.defaultUrl : options.url;
	}

	setBaseLayers = async (newBaseLayers) => {		
		//TODO:19a-move fetch to fetchservice
		try {
			let mappedArray = newBaseLayers.map((item) => {
				let mappedItem = {};
				mappedItem.active = item.active;
				mappedItem.icon = item.icon;
				mappedItem.name = item.name;
				mappedItem.minzoom = item.minZoom;
				mappedItem.maxzoom = item.maxZoom;
				mappedItem.link = item.tilelayerurl;
				mappedItem.tilelayerurl = item.link;
				return mappedItem;
			});

			let requestBody = JSON.stringify(mappedArray);

			let response = await fetch(this.url, {
				method: 'POST',
				body: requestBody,
				headers: {
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + this.jwt
				},
				credentials: 'include',
			});
			let result = await response.status;
			return result;

		}
		catch (error) {
			console.log("baselayersservice service error:" + error); // from creation or business logic
		}
    }

	addNew = () => {
		return {
			active: false,
			icon: "[]",
			name: "new baselayer",
			minZoom: 3,
			maxZoom: 19,
			link: "",
			layer: {}
        }
    }

	getBaseLayers = async () => {
		//TODO:19b-move to fetchservice
		let baselayers = await (await fetch(this.url, {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.jwt
			},
			credentials: 'include'
		})).json();

		let result = baselayers.map((item) => {
			let mappedItem = {};
			mappedItem.active = item.active;
			mappedItem.icon = item.icon;
			mappedItem.name = item.name;
			mappedItem.minZoom = item.minzoom;
			mappedItem.maxZoom = item.maxzoom;

			mappedItem.link = item.tilelayerurl;
			mappedItem.layer = L.tileLayer(item.tilelayerurl);
			//min max zoom bug fix
			mappedItem.layer.options.minZoom = item.minzoom;
			mappedItem.layer.options.maxZoom =24;
			mappedItem.layer.options.maxNativeZoom = item.maxzoom;
			return mappedItem;
		});

		//console.log(JSON.stringify(result));
		return result;
	}



}