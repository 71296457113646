//vgis styler class
import { vgislevel, vgislevelCascadeFilter } from './vgislevel';
import L from 'leaflet';

export class vgisstyler{

	//options:
	/*
	{
		apiLevels: [],
		uncollapsedGroups: [],
		redrawLayerFunc: f(),
		ignorations: [],
		translations: [],
	}
	*/	
    constructor(options) {
		this.redrawVector = options.redrawLayerFunc;
		this.uncollapsedGroups = options.uncollapsedGroups;	
		let newLevels = [];
		for (let item of options.apiLevels) {
			let newItemOpts = item;
			newItemOpts.belongsFilter = new BelongsFilter(item.belongsFilterOptions).filter;
			newItemOpts.redrawFunction = options.redrawLayerFunc;
			newLevels.push(new vgislevel(newItemOpts));
		}
		this.levels = newLevels;
		this.layerFilter = new vgislevelCascadeFilter(this.levels).cascadeFilter;
		this.popupService = options.popupService;
		this.ignorations = options.ignorations;
		this.translations = options.translations;
	}	

	getPanelLayersContent = () => {
		return this.getPanelLayersContentBase(this.levels, this.uncollapsedGroups);
	}

	
	//getFeatureId
	getFeatureId = (e) => {
		let oid = e.properties.OBJECTID;
		return oid;
	}

    //universal field translators
	getFieldTranslation = (fieldName) => {
		if (this.translations[fieldName] !== undefined)
		{
			return this.translations[fieldName];
		}
		else
		{
			return fieldName;
		}		
	}

	//universal popup content
	getPopupContent = (layer) => {
		let popupContent = "<h5>" + layer.options.name + "</h5>";
		for (var propt in layer.properties) {
			//is ignored?
			if (!this.ignorations.includes(propt)) {
				popupContent += "<b>" + this.getFieldTranslation(propt) + ": </b>" + layer.properties[propt] + "<br>";
            }
		}
		return popupContent;
	}

    getPanelLayersContentBase = (levels, uncollapsedGroups) => {
		let result = [];
		levels.forEach( (item) => {
			let groupName = item.groupname;	
			let findResult = result.find( (element) => {
				if (element.group == groupName)
				{
					return element;					
				}
			});
			if (findResult === undefined){	
                findResult = {
					group : groupName,
					collapsed: (uncollapsedGroups === undefined || uncollapsedGroups.includes(groupName)) ? false : true,
					layers: []
				};	
				result.push(findResult);
			}
            let newLayer = {
                icon: item.levelicon,
                name: item.levelname,
                layer: item,
                active: item.visible,
            }			
            findResult.layers.push(newLayer);	
		});
		return result;
    }
}

export class StaticStyler {
	constructor(style) {
		if (style.iconPath !== null) {
			let newStyle = {
				"name": name,
				icon: L.icon({
					iconUrl: style.iconPath,
					iconSize: [style.iconWidth, style.iconHeight],
				})
			};
			this._style = newStyle;
		}
		else {
			this._style = style;
        }
		
	}
	GetStyle = () => {
		return this._style;
	}
}

export class TypedStyler {

	constructor(fieldName, stylesArray) {
		this.fName = fieldName;
		this.Styles = stylesArray;
	}

	GetStyle = (e) => {
		return this.Styles[e[this.fName]];
	}
}

export class BelongsFilter {
	// options:
	// {field1 = value,
	// ..
	//  field2 = value
	// }
	constructor(options) {
		this._filterOptions = options;		
	}

	//belongs filter

	filter = (props) => {
	//filter = (props, zoom) => {

		for (var propt in this._filterOptions) {
			if (props[propt] === undefined) {
				return false;
			}
			if (props[propt] != this._filterOptions[propt]) {
				return false;
			}
		}
		return true;
    }
}