<template>
    <h2>{{messageResult}}</h2>

</template>

<script>
    import { useRouter } from "vue-router";
    import { toRef } from 'vue'

    const messagevalueDef = "AuthNeeded";

    export default {
        props: {
            messagevalue: { default: messagevalueDef },
            redirect: {default: false}
        },
        setup(props) {
            //let msgValue = props.messagevalue;
            let msgValue = toRef(props, 'messagevalue')
            //let propsRedirect = props.redirect;
            let propsRedirect = toRef(props, 'redirect');
            if (msgValue.value == "AuthNeeded") { msgValue.value = 'Необходима аутентификация!'}
            const messageResult = "Ошибка: " + msgValue.value;
            
            if (propsRedirect.value === true) {

                useRouter().push("/login");
            }

            return {
                messageResult
            }

        }
    }   
</script>

