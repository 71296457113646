<template>
    <div v-if="isAdmin">
        <h2>Псевдонимы полей</h2>
        <div v-if="!isSaved">
            <div class="container">
                <table class="table">
                    <thead class="thead-dark">
                        <tr>
                            <th scope="col" class="col-1">#</th>
                            <th scope="col" class="col-2">Имя</th>
                            <th scope="col" class="col-4">Псевдоним</th>
                            <th scope="col" class="col-1">Удалить</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(translation, index) in translationsList" :key="index">
                            <td class="col-1" scope="row">{{index+1}}</td>
                            <td class="col-2">
                                <input class="form-control" type="text" v-model="translation['name']" /></td>
                            <td class="col-4"><input class="form-control" type="text" v-model="translation['alias']" /></td>
                            <td class="col-1"><button @click="removeItem(translation)" class="btn btn-dark disabled" aria-hidden="true"><span class="fa fa-recycle"></span></button></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <button class="btn btn-dark" @click="saveBtn">Сохранить</button>
            <button class="btn btn-dark ml-1" @click="addNewTranslation">Добавить псевдоним</button>
        </div>
        <div v-else>
            <SavedMessage />
        </div>
    </div>
    <div v-else>
        <ErrorMessage messagevalue="Нужны права администратора"></ErrorMessage>
    </div>
    <p>

    </p>
</template>
<script>
    import {  onMounted, computed,ref } from 'vue';
    import { useStore } from 'vuex';
    import ErrorMessage from '../Shared/ErrorMessage.vue';
    import SavedMessage from '../Shared/SavedMessage.vue';
    import { loginservice } from '../../services/loginservice';
    import { popupservice } from '../../services/popupservice';
    export default {
        name: 'Translations',
        setup() {
            const store = useStore();
            const isAdmin = computed(() => (store.state.isAdmin));
            const isSaved = ref(false);
            let popupService = null;
            const translationsList = ref([]);
            onMounted(async () => {
                let logonResult = await loginservice.checkLogin();
                //Check Auth
                if (logonResult.result === true) {
                    let options = {
                        jwt: logonResult.credits.jwt,
                        username: logonResult.credits.username
                    }
                    popupService = new popupservice(options);
                    let translationsObj = await popupService.getTranslations();
                    //MAP obj to array
                    for (let item in translationsObj) {
                        let newItem = {
                            name: item,
                            alias: translationsObj[item]
                        }
                        translationsList.value.push(newItem);
                    }
                }
            });

            const addNewTranslation = async () => {
                try {
                    let newTranslation = {
                        name: 'NEW_NAME',
                        alias: "Новый псевдоним"
                    };
                    translationsList.value.push(newTranslation);
                }
                catch {
                    console.log("addNewTranslation error");
                }
            }

            const saveBtn = async () => {
                //unMAP array to obj
                let newItem = {};
                for (let item in translationsList.value) {  
                    newItem[translationsList.value[item].name] = translationsList.value[item].alias;
                }

                let saveResult = await popupService.setTranslations(newItem);
                if (saveResult) {
                    isSaved.value = true;
                }
                else {
                    console.log("and error occured when save users list");
                }
            }

            const removeItem = async(translation) => {               
                let itemIndex = translationsList.value.findIndex(o => o.name == translation.name );
                if (itemIndex > -1) {
                    translationsList.value.splice(itemIndex, 1);
                    return;
                }
                console.log("Error! Unable to removeItem with name: " + translation.name);                
           }
            return {
                isAdmin,
                isSaved,
                saveBtn,
                addNewTranslation,
                removeItem,
                translationsList
            }
        },
        components: {
            ErrorMessage,
            SavedMessage
        }
    }
</script>
