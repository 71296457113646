<template>
    <!-- Modal -->
    <div class="modal fade" id="searchModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Поиск</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Закрыть">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">

                    <div class="container-fluid">
                        <form class="row justify-content-md-center mb-3">
                            <input class="form-control mr-sm-1 col-9" type="search" placeholder="Введите запрос" v-model="searchRequest" aria-label="Введите запрос">
                            <button class="btn btn-outline-success col-auto" @click="searchClicked">Найти</button>
                        </form>                        
                    </div>                    

                    <!-- Scrollable modal / Search Result -->
                    <div class="container-fluid">

                        <div class="card mb-2" v-for="item in searchResult" :key="item.Title">
                            <div class="card-header">
                                {{item.Title}}  [<a href="#" @click="addMark(item.Coords.X, item.Coords.Y, item.popupContent)">на карту</a>]
                            </div>
                            <div>
                                <ul class="list-group list-group-flush">
                                    <li class="list-group-item" v-for="(resultItem, index) in item.ResultItems" :key="index">{{resultItem}}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal"  >Закрыть</button>
                    <button type="button" class="btn btn-primary" @click="clearSearch">Очистить</button>
                </div>
            </div>
        </div>
    </div>

    <div v-if="isErrorOrNotLogged" id="mapview">
        <h1>Просмотр карты</h1>
        <ErrorMessage :messagevalue="message" :redirect="isredirectonerror"></ErrorMessage>
    </div>
    <div v-else id="map"></div>

</template>

<script>
    import { onMounted, ref } from 'vue';
    import * as L from 'leaflet';
    import { vgiscore } from '../js/vgiscore';
    import { baselayersservice } from '../services/baselayersservice';    
    import { vectorlayerservice } from '../services/vectorlayerservice';    
    import { popupservice } from '../services/popupservice';
    import { loginservice } from '../services/loginservice';
    import { searchservice } from '../services/searchservice';
    import { vgisstyler } from '../js/vgisstyler';
    import * as PanelLayers from 'leaflet-panel-layers';


    import ErrorMessage from './Shared/ErrorMessage.vue';

    export default {
        name: "Mapview",
        setup() {
            //Login Service
            const isErrorOrNotLogged = ref(false);
            const message = ref('Необходима аутентификация!');   
            const isredirectonerror = ref('false');

            //Search Service
            const isSearchEnabled = ref(false);
            const searchService = ref(null);
            const searchRequest = ref(null);
            const searchResult = ref(null);
            const lmarkers = ref([]);
            //get searchnames for MapView.vue search
            const currentSearchNames = ref([]);
            const lmap = ref(null);

            const legendDiv = ref(null);
            const legendVisible = ref(true);
            //on-off legend
            const legendswitcherclick = () => {
                legendVisible.value = !legendVisible.value;
                if (!legendVisible.value) {
                    legendDiv.value.style.visibility = 'hidden';
                   // console.log("hidden");
                }
                else {
                    legendDiv.value.style.visibility = 'visible';
                   // console.log("visible");
                }
            }

            const clearSearch = async (/*event*/) => {
                searchResult.value = null;
                searchRequest.value = null;

                lmarkers.value.forEach(element => {
                    element.removeFrom(lmap.value);
                });
                lmarkers.value = [];
            }

            const addMark = async (x, y, message) => {
                let latlng = L.latLng(y, x);
                let markerOptions = {
                    icon: L.icon({
                        iconUrl: 'marker.png',
                        iconSize: [42, 56],
                    })
                };
                let newMarker = L.marker([y, x], markerOptions).addTo(lmap.value);
                newMarker.bindPopup(message)
                lmarkers.value.push(newMarker);
                lmap.value.panTo(latlng);
            }

            const searchClicked = async (event) => {
                if (event) {
                    event.preventDefault()
                }

                if (isSearchEnabled.value) {
                    //let requestResult = await searchService.value.findObjects(searchRequest.value);
                    let searchNamesStr = "";
                    for (let item in currentSearchNames.value) {
                        
                        searchNamesStr += currentSearchNames.value[item] + ",";
                    }
                    if (searchNamesStr.length > 1) {
                        searchNamesStr = searchNamesStr.slice(0, -1);
                    }

                    let requestResult = await searchService.value.findObjectsWithNames(searchRequest.value, searchNamesStr);

                    for (let item of requestResult) {
                        item.popupContent = "<b>" + item.Title + "</b><br/>";
                        for (let subItem of item.ResultItems) {
                            item.popupContent += subItem + "<br/>";
                        }

                    }
                    searchResult.value = requestResult;
                }
                else {
                    alert('search not allowed for this page!');
                }
            }

            onMounted(async () => {
                try {
                    let logonResult = await loginservice.checkLogin();
                    //Check Auth
                    if (logonResult.result === true) {

                        message.value = "allok";
                        let credits = logonResult.credits;

                        //check search service is enabled
                        searchService.value = new searchservice(credits);
                        isSearchEnabled.value = await searchService.value.checkSearchEnabled();

                        //create map object
                        let odhMapOptions = {
                            zoomControl: false,
                            center: { lat: 55.721, lng: 37.632 },
                            zoom: 12,
                            searchEnabled: isSearchEnabled.value
                        };
                        let odhMap = new vgiscore("map", odhMapOptions);
                        lmap.value = odhMap.Lmap;

                        //get base layers
                        let baseLayers = await (new baselayersservice(credits)).getBaseLayers();                        
                        let panelBaseLayers = {
                            group: "Базовые карты",
                            collapsed: true,
                            layers: baseLayers
                        };
                        //get options
                        let legendOpts = {
                            collapsibleGroups: true,
                            autoZIndex: false,
                            compact: true,
                            position: 'topleft',
                        };

                        //get vector layer service
                        let vtService = new vectorlayerservice(credits);
                        let uncollapsedGroups = await vtService.getUncollapsedGroups();
                        let apiLevels = await vtService.getLevels();
                        
                        //get searchnames for MapView.vue search
                        for (let item of apiLevels) {
                            let searchName = item.searchname;
                            if (searchName !== undefined && !currentSearchNames.value.includes(searchName)) {
                                currentSearchNames.value.push("\"" + searchName + "\"");
                            }
                        }

                        let ppService = new popupservice(credits);
                        let ppIgnorations = await ppService.getIgnorations();
                        let ppTranslations = await ppService.getTranslations();

                        let stylerOptions = {
                            apiLevels: apiLevels,
                            redrawLayerFunc: odhMap.redrawVectorGridLayer,
                            uncollapsedGroups: uncollapsedGroups,
                            ignorations: ppIgnorations,
                            translations: ppTranslations
                        };
                        let vtStyler = new vgisstyler(stylerOptions);

                        //get from url vectorTileStyling
                        let vtStyling = await vtService.getStyling();

                        let vtHighliteStyle = await vtService.getHighliteStyle();

                        let localtilesurlVectorTileOptions = {
                            rendererFactory: L.canvas.tile, //L.svg.tile slow, L.canvas.tile fast
                            attribution: 'geoserver tiles',
                            vectorTileLayerStyles: vtStyling,
                            minZoom: 3,
                            maxZoom: 24,
                            zIndex: 1000,
                            interactive: true,
                            highlitestyle: vtHighliteStyle,
                        };

                        let vectorLayerUrl = await vtService.getUrl();
                        odhMap.addVectorGridLayer(vectorLayerUrl, localtilesurlVectorTileOptions, vtStyler);

                        //add layers control
                        let overLayers = vtStyler.getPanelLayersContent();
                        odhMap.Lmap.addControl(new PanelLayers([panelBaseLayers], overLayers, legendOpts));

                        //legend visibility switcher
                        let searchButton = document.getElementById('vgislegendswitcher');
                        searchButton.addEventListener("click", legendswitcherclick);
                        legendDiv.value = document.getElementsByClassName("leaflet-panel-layers")[0];


                    }
                    else {
                        isErrorOrNotLogged.value = true;
                        isredirectonerror.value = true;
                    }
                } catch (e) {
                    message.value = "catch on Mapview.vue: " + e;
                    isredirectonerror.value = false;
                    isErrorOrNotLogged.value = true;
                }
            });
            return {
                isErrorOrNotLogged,
                isredirectonerror,
                message,
                searchClicked,
                addMark,
                clearSearch,
                searchRequest,
                searchResult,
                currentSearchNames
            }
        },
        components: {
            ErrorMessage
        },


            
    }
</script>

<style scoped>
    #mapview {
        background-color: #FFFFFF;
        border: 1px solid #CCCCCC;
        padding: 00px;
        margin-top: 0px;
    }

    #map {
        position: absolute;
        top: 00px;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: white;
    }


</style>