<template>
    <div v-if="isAdmin">
        <h2>Базовые карты</h2>
        <div v-if="!isSaved">
            <div class="container-fluid">
                <table class="table">
                    <thead class="thead-dark">
                        <tr class="d-flex">
                            <th scope="col" class="col-1">#</th>
                            <th scope="col" class="col-1">Active</th>
                            <th scope="col" class="col-2">Icon</th>
                            <th scope="col" class="col-2">Name</th>
                            <th scope="col" class="col-1">maxZoom</th>
                            <th scope="col" class="col-1">minZoom</th>
                            <th scope="col" class="col-3">URL</th>
                            <th scope="col" class="col-1">Remove</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="d-flex" v-for="(baseLayer, index) in baseLayers" :key="baseLayer.name">
                            <td class="col-1" scope="row">{{index+1}}</td>
                            <td class="col-1"><input type="checkbox" v-model="baseLayer['active']" /></td>
                            <td class="col-2"><input class="form-control" type="text" v-model="baseLayer['icon']" /></td>
                            <td class="col-2"><input class="form-control" type="text" v-model="baseLayer['name']" /></td>
                            <td class="col-1"><input class="form-control" type="text" v-model="baseLayer['maxZoom']" /></td>
                            <td class="col-1"><input class="form-control" type="text" v-model="baseLayer['minZoom']" /></td>
                            <td class="col-3"><input class="form-control" type="text" v-model="baseLayer['link']" /></td>
                            <td class="col-1"><button @click="removeItem(baseLayer.name)" class="btn btn-dark disabled" aria-hidden="true"><span class="fa fa-recycle"></span></button></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <button class="btn btn-dark" @click="saveBtn">Сохранить</button>
            <button class="btn btn-dark ml-1" @click="addNew">Добавить новый</button>
        </div>
        <div v-else>
            <SavedMessage/>
        </div>
        
    </div>
    <div v-else>
        <ErrorMessage messagevalue="Нужны права администратора"></ErrorMessage>
    </div>    
</template>

<script>
    import {  onMounted, computed,ref } from 'vue';
    import { useStore } from 'vuex';
    import ErrorMessage from '../Shared/ErrorMessage.vue';
    import SavedMessage from '../Shared/SavedMessage.vue';
    import { baselayersservice } from '../../services/baselayersservice';
    import { loginservice } from '../../services/loginservice';


    export default {
        name: 'ChangePassword',
        setup() {
            const store = useStore();
            const isAdmin = computed(() => (store.state.isAdmin));
            const isSaved = ref(false);
            const baseLayers = ref([]);
            let baselayersService = null;
            
            onMounted(async () => {
                let logonResult = await loginservice.checkLogin();
                //Check Auth
                if (logonResult.result === true) {
                    let credits = logonResult.credits;
                    baselayersService = new baselayersservice(credits);
                    baseLayers.value = await (baselayersService).getBaseLayers();
                }
            });

            const addNew = async () => {
                var newLayer = baselayersService.addNew();
                baseLayers.value.push(newLayer);
            }

            const saveBtn = async () => {
                await baselayersService.setBaseLayers(baseLayers.value);
                isSaved.value = true;
            }
            const removeItem = async (itemName) => {
                var index = baseLayers.value.findIndex( o => o.name == itemName);
                if (index > -1) {
                    baseLayers.value.splice(index, 1);
                }
            }
           
            return {
                isAdmin,
                saveBtn,
                addNew,
                removeItem,
                baseLayers,
                isSaved
            }
        },

        components: {
            ErrorMessage,
            SavedMessage
        }
    }
</script>