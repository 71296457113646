<template>   
    <h2>Пользователь `{{username}}`</h2>

    <ChangePassword></ChangePassword>
</template>

<script>
    import { useStore } from 'vuex';    
    import { computed } from 'vue';
    import ChangePassword from './ChangePassword';
export default{

    setup(){
        const store = useStore();
        const username = computed(() => store.state.username);
        return {
         username
        }
        },
        components: {
            ChangePassword
        }
}
 
</script>