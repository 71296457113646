//vgis level (extends L.Layer)
// (vectorgridlayer sublevels)
//copyright (c) 2021, Andrey Khozyaychikov

import * as L from 'leaflet';

export class vgislevel extends L.Layer {

	static defaultFilter = () => false;

	//debounde options
    static isRedrawPending = false;
	static debounceTimeMs = 500;
	static timerHandler = 0;

	levelFilter = (props, zoom) =>
	{
		//1. check for visibility
		//2. check for feature is for this level
		if (this.visible) {
			if (this.belongsFilter(props, zoom)) {
				return true;
			}
		}
		return false;
	}

	//
	//  options{
	//		groupname: string, default "default"
	//		levelname: string, default "unknown"
	//		searchname: string, default "unknown"
	//		visible: bool, default true
	//		belongsFilter: f(props, zoom), default vgislevel.defaultFilter
	//      redrawFunction: f(), default vgislevel.stubRedraw
    //  }
	constructor(options)
	{		
		super();

		this.groupname = (options === undefined || options.groupname === undefined) ? "default": options.groupname;
		this.levelname = (options === undefined || options.levelname === undefined) ? "unknown" : options.levelname;		
		//searchname support for (vgislevel.js)
		this.searchname = (options === undefined || options.searchname === undefined) ? "unknown" : options.searchname;

		this.levelicon = (options === undefined || options.levelicon === undefined) ? "*": options.levelicon;		
		this.visible = (options === undefined || options.visible === undefined) ? true: options.visible;
		this.belongsFilter = (options === undefined || options.belongsFilter === undefined) ? vgislevel.defaultFilter: options.belongsFilter;
		this.redrawFunction = (options === undefined || options.redrawFunction === undefined) ? vgislevel.stubRedraw: options.redrawFunction;
	}

	//L.Layer overrides
	onRemove = (/*map*/) => {
		this.visible = false;
		this.redrawFunction();
		console.log('onRemove '+ this.levelname);
	}

	onAdd = (/*map*/) => {
		this.visible = true;
		//debounce redrawFunction calling
		this.debouncedRedraw();		
	}

	addTo = (/*map*/) => {
		//do nothins
		console.log('addTo ' + this.levelname)
	}

	//debounced redraw function
	debouncedRedraw = () => {
		
		//this.redrawFunction();
		if (!vgislevel.isRedrawPending) {
			vgislevel.timerHandler = setTimeout(() => {
				this.redrawFunction()
				vgislevel.isRedrawPending = false;
			},
				vgislevel.debounceTimeMs);
			console.log('onAdd ' + this.levelname);
			vgislevel.isRedrawPending = true;
		}
		else {
			console.log('onAdd ' + this.levelname + ".. pended");
        }		
    }


	static stubRedraw = () => {
		console.log("stubRedraw() called");
	}
}

export class vgislevelCascadeFilter
{
	constructor(levels)
	{
		this.levels = levels;
	}
	cascadeFilter = (props, zoom) =>
	{
		for (let currentvgisLevel in this.levels)
		{
			if (this.levels[currentvgisLevel].levelFilter(props, zoom))
			{
				return true;
			}
		}
		return false;// not belongs, not visible
	}
}