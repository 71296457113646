import { fetchservice } from './fetchservice';

export class searchservice extends fetchservice {
	static isSearchEnabledUrl = "/api/search/issearchenabled";
	static searchEndPoint = "/api/search/findObjects";
	static searchWithNamesEndPoint = "/api/search/findObjectsWithNames";

	//options {
	// jwt - bearer token	
	// }
	constructor(options) {		
		super(options);
		this.jwt = options.jwt;	
	}

	checkSearchEnabled = async () => {
		let result = await this.getFetchResult(searchservice.isSearchEnabledUrl);
		return result;
	}

	findObjects = async (requestString) => {
		requestString = requestString.replace("+", "%2b");
		let url = searchservice.searchEndPoint + "?request=" + requestString;
		url = encodeURI(url);
		let result = await this.getFetchResult(url);
		return result;
	}

	findObjectsWithNames = async (requestString, namesArray) => {
		requestString = requestString.replace("+", "%2b");
		let url = searchservice.searchWithNamesEndPoint + "?request=" + requestString + "&names=" + namesArray;
		url = encodeURI(url);
		let result = await this.getFetchResult(url);
		return result;
	}
}