<template>
    <header >
        <nav style="z-index:1000;" class="navbar  navbar-expand-sm navbar-toggleable-sm navbar-light bg-white border-bottom box-shadow mb-1">
            <div class="container">
                <a class="navbar-brand">{{appName}}</a>
                <button class="navbar-toggler"
                        type="button"
                        data-toggle="collapse"
                        data-target=".navbar-collapse"
                        aria-label="Toggle navigation"
                        @click="toggle">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="navbar-collapse collapse d-sm-inline-flex flex-sm-row-reverse"
                     v-bind:class="{show: isExpanded}">
                    <ul class="navbar-nav flex-grow">
                        <li class="nav-item">
                            <router-link :to="{ name: 'Mapview' }" class="nav-link text-dark">Просмотр карты</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link :to="{ name: 'Admin' }" class="nav-link text-dark">Настройки</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link :to="{ name: 'Messages' }" class="nav-link text-dark">Что нового <span class="badge badge-secondary" v-if="unreadedMessagesExist">{{unreadedMessages}}</span></router-link>
                        </li>
                        <li class="nav-item">
                            <span v-if="auth" class="nav-link">
                                {{username}} (<a href="#" @click="logout">Выход</a>)
                            </span>
                            <router-link :to="{ name: 'Login' }" class="nav-link text-dark" v-else>Вход</router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    </header>
</template>


<style>
    a.navbar-brand {
        white-space: normal;
        text-align: center;
        word-break: break-all;
    }

    html {
        font-size: 14px;
    }

    @media (min-width: 768px) {
        html {
            font-size: 16px;
        }
    }

    .box-shadow {
        box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
    }
</style>
<script>
    import { onMounted, computed, ref} from 'vue';
    import { useStore } from "vuex";
    import {useRouter} from "vue-router"
    import { loginservice } from "../services/loginservice";
    import { fetchservice } from "../services/fetchservice";
    //import { messagesservice } from "../services/messagesservice";


    export default {
        name: "NavMenu",
        setup() {
            const isExpanded = ref(false);
            const store = useStore();
            const router = useRouter();
            const auth = computed(() => store.state.authenticated);
            const username = computed(() => store.state.username);
            
            const appName = ref("Gis3App!");
            let unreadedMessages = computed(() => store.state.unreadedmessagescount);
            let unreadedMessagesExist = computed(() => store.state.hasunreadedmessages);

            onMounted(async () => {
                //appName
                let thisAppName = await new fetchservice().getFetchResult("/api/login/appname");                
                appName.value = thisAppName.appName;  
            });


            const logout = async () => {  
                await loginservice.logout();    
                await store.dispatch('setAuth', loginservice.unknownUser);
                await router.push('/login');
            }

            const collapse = () => {
                isExpanded.value = false;
            }

            const toggle = () => {
                //console.log("toggle pressed!");
                isExpanded.value = !isExpanded.value;
            }

            return {
                auth,
                logout,
                collapse,
                toggle,
                username,                
                isExpanded,
                appName,

                unreadedMessagesExist,
                unreadedMessages,

            }
        }
    }
</script>
