<!--

https://www.thepolyglotdeveloper.com/2018/04/simple-user-login-vuejs-web-application/

-->
<template>
    <div id="login" class="col-7">
        <h1>Вход</h1>
        <form @submit.prevent="login" >
            <div class="form-group ">
                <div class="input-group-prepend">
                    <span class="input-group-text">Имя и пароль</span>
                </div>
                <input type="text" aria-label="First name" class="form-control" name="username" v-model="input.username" placeholder="Имя" />
                <input aria-label="Last name" class="form-control" type="password" name="password" v-model="input.userpassword" placeholder="Пароль" />
                <p class="error" v-if="input.isError">Неправильные имя или пароль</p>
                <button class="btn btn-primary mt-4" type="submit">Войти</button>
                
            </div>
        </form>      
    </div>
</template>

<script>
    import { reactive } from 'vue';
    import { useRouter } from "vue-router";

    export default {
        name: 'Login',
        setup() {
            const input = reactive({
                username: '',
                userpassword: '',
                isError: false
            });
            const router = useRouter();

            const login = async () => {
                let response = await fetch('/api/login', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    credentials: 'include',
                    body: JSON.stringify(input)
                });

                let result;
                if (await response.status == 401) {
                    result = '/login';
                    input.isError = true;
                }
                else {

                    result = '/';
                    input.isError = false;
                }
                await router.push(result);
            }
            return {
                input,
                login
            }
        }


    }
</script>

<style scoped>
    #login {
        width: 500px;
        border: 1px solid #CCCCCC;
        background-color: #FFFFFF;
        margin: auto;
        margin-top: 200px;
        padding: 20px;
    }

    .error{
        color: red;
    }
</style>