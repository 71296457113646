<template>
    <div v-if="isAdmin">
        <h2>Скрытые поля</h2>
        <div v-if="!isSaved">
            <div class="container">
                <table class="table">
                    <thead class="thead-dark">
                        <tr>
                            <th scope="col" class="col-1">#</th>
                            <th scope="col" class="col-4">Имя</th>
                            <th scope="col" class="col-1">Удалить</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(ignoration, index) in ignorationsList" :key="index">
                            <td class="col-1" scope="row">{{index+1}}</td>
                            <td class="col-4">
                                <input class="form-control" type="text" v-model="ignorationsList[index]" />
                            </td>
                            <td class="col-1"><button @click="removeItem(ignoration)" class="btn btn-dark disabled" aria-hidden="true"><span class="fa fa-recycle"></span></button></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <button class="btn btn-dark" @click="saveBtn">Сохранить</button>
            <button class="btn btn-dark ml-1" @click="addNewIgnoration">Добавить в список</button>
        </div>
        <div v-else>
            <SavedMessage />
        </div>
    </div>
    <div v-else>
        <ErrorMessage messagevalue="Нужны права администратора"></ErrorMessage>
    </div>
    <p>

    </p>
</template>
<script>
    import { onMounted, computed, ref } from 'vue';
    import { useStore } from 'vuex';
    import ErrorMessage from '../Shared/ErrorMessage.vue';
    import SavedMessage from '../Shared/SavedMessage.vue';
    import { loginservice } from '../../services/loginservice';
    import { popupservice } from '../../services/popupservice';
    export default {
        name: 'Translations',
        setup() {
            const store = useStore();
            const isAdmin = computed(() => (store.state.isAdmin));
            const isSaved = ref(false);
            let popupService = null;
            const ignorationsList = ref([]);
            onMounted(async () => {
                let logonResult = await loginservice.checkLogin();
                //Check Auth
                if (logonResult.result === true) {
                    let options = {
                        jwt: logonResult.credits.jwt,
                        username: logonResult.credits.username
                    }
                    popupService = new popupservice(options);
                    ignorationsList.value = await popupService.getIgnorations();
                }
            });

            const addNewIgnoration = async () => {
                try {
                    ignorationsList.value.push("Новое_Поле");
                }
                catch {
                    console.log("addNewIgnoration error");
                }
            }

            const saveBtn = async () => {

                let saveResult = await popupService.setIgnorations(ignorationsList.value);
                if (saveResult) {
                    isSaved.value = true;
                }
                else {
                    console.log("and error occured when save users list");
                }
            }

            const removeItem = async (ignoration) => {
                let itemIndex = ignorationsList.value.findIndex(o => o == ignoration);
                if (itemIndex > -1) {
                    ignorationsList.value.splice(itemIndex, 1);
                    return;
                }
                console.log("Error! Unable to removeItem with name: " + ignoration);
            }
            return {
                isAdmin,
                isSaved,
                saveBtn,
                addNewIgnoration,
                removeItem,
                ignorationsList
            }
        },
        components: {
            ErrorMessage,
            SavedMessage
        }
    }
</script>
