//vgis core
//copyright (c) 2021, 2022 Andrey Khozyaychikov
	
import L from 'leaflet';
import { } from "./leaflet_patched/Leaflet.VectorGrid.bundled";
import { } from "./leaflet_patched/Leaflet.Control.Custom";
//import { vgislevel, vgislevelCascadeFilter } from './vgislevel';

export class vgiscore {
	//mapOptions
	//	zoomControl: true|false
	//  center: latlng
	//  zoom: [0-18]
	//	searchModule: vgissearch class instance

	constructor(mapDiv, mapOptions)
	{			
		//properties
		//	div, layers
		this.mapDiv = mapDiv;
		this.baseLayers = [];
		this.vectorgridlayer = null;
		this.vectorgridStyler = null;
		//	search
		this.searchModule = null;
		this.searchValue = null;
		this.searchMarkers = [];
		this.searchButton = null;
		this.searchClearButton = null;
		this.searchControl = null;
		//	highLite And Popup
		this.highlitedID = 0;

		//leaflet init
		this.Lmap = L.map(mapDiv, 
		{
			zoomControl: (mapOptions === undefined || mapOptions.zoomControl === undefined) ? true : mapOptions.zoomControl,
			maxZoom: 24
		});

		let map = this.Lmap;
		//set mapoptions
		let startCenter = (mapOptions === undefined || mapOptions.center===undefined) ? {lat: 55, lng:37} : mapOptions.center;
		let startZoom = (mapOptions === undefined || mapOptions.zoom===undefined) ? 10 : mapOptions.zoom;
		map.setView(startCenter, startZoom);


		//popup closing - reset highlite

		map.on('popupclose', () => {
			this.resetHighlite();
		});

		/////////////////////////////////////////////////////
		//legend visibilty switcher button
		/////////////////////////////////////////////////////

		L.control.custom({
			position: 'topleft',
			content: '<div class="input-group">' +
				'        <button class="btn btn-light border-2 border-dark" type="button" id="vgislegendswitcher"><i class="fas fa-list-ul"></i></button>' +
				'</div>'
		}).addTo(map);


		///////////////////////////////////////////////////
		//                  search UI
		///////////////////////////////////////////////////
		if (mapOptions !== undefined && mapOptions.searchEnabled === true) {
			L.control.custom({
				position: 'topright',
				content: '<div class="input-group">' +
					'        <button class="btn btn-light border-2 border-dark" type="button" data-toggle="modal" data-target="#searchModal"><i class="fas fa-search-location"></i></button>' +
					'</div>',
				classes: '',
				style:
				{
					/*
						position: 'absolute',
						left: '50px',
						top: '0px',
						width: '300px',
						*/
				},
			}).addTo(map);
		//add event listeners
		/*
		this.searchButton = document.getElementById('vgissearchbutton');
		this.searchButton.addEventListener("click", this.searchclick);

		this.searchControl = document.getElementById('vgissearchtext');
		this.searchControl.addEventListener("change", () => { this.searchValue = this.searchControl.value; })

		this.searchClearButton = document.getElementById('vgisclearsearchbutton');
		this.searchClearButton.addEventListener("click", this.searchclearclick);
		*/
        }		
	}	




	//base map
	addBaseMap = (options) => {
		let newBaseLayer = L.tileLayer(options.link, {
			attribution: (options.attribution === undefined) ? "unknown" : options.attribution,
			opacity: (options.opacity === undefined)  ? 1 : options.opacity,
			minZoom: (options.minZoom === undefined)  ? 3 : options.minZoom,
			maxZoom: (options.maxZoom === undefined)  ? 24 : options.maxZoom,
		}).addTo(this.Lmap);
		
		this.baseLayers.push(newBaseLayer);
	}



	//
	//Vector grid methods
	//
	addVectorGridLayer = (url, options, styler) => {
		//add options to options
		this.vectorgridlayerStyler = styler;
		options.filter = (props, zoom) => {
			return this.vectorgridlayerStyler.layerFilter(props, zoom);
		};	
		options.getFeatureId = this.vectorgridlayerStyler.getFeatureId;

		//this.vectorgridlayer = L.vectorGrid.protobuf(url, options);		
		this.vectorgridlayer = L.vectorGrid.protobuf(url, options);			

		this.highlitestyle = options.highlitestyle;
		this.vectorgridlayer.on('click', this.highLiteAndPopup).addTo(this.Lmap);


		//TOUCH zoom problems with vectorgrid
		this.Lmap.on('zoomend', () => {
			if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
				this.redrawVectorGridLayer();
			}
		});

	}

	redrawVectorGridLayer = () => {
		if (this.vectorgridlayer != null)
		{
			this.vectorgridlayer.redraw();
		}
	}

	highLiteAndPopup = (e) => {
		//popup
		L.popup()
			.setContent(this.vectorgridlayerStyler.getPopupContent(e.layer))
			.setLatLng(e.latlng)
			.openOn(this.Lmap);
		this.vectorgridlayer.resetFeatureStyle(this.highlitedID);
		this.highlitedID = this.vectorgridlayerStyler.getFeatureId(e.layer);
		this.vectorgridlayer.setFeatureStyle(this.highlitedID, this.highlitestyle);
	}
	
	resetHighlite = () =>
	{
		if (this.vectorgridlayer != null)
		{
			this.vectorgridlayer.resetFeatureStyle(this.highlitedID);
			this.highlitedID = 0;
		}
	}	
}
