import { fetchservice } from './fetchservice';

export class messagesservice extends fetchservice {
	static unreadedMessagesEndPoint = "/api/messages/getMessages";
	static setMessagesEndPoint = "/api/messages/setMessages";
	static unreadedMessagesCountEndPoint = "/api/messages/getUnreadedMessagesCount";
	
	//options {
	// jwt - bearer token	
	// }
	constructor(options) {
		super(options);
		if (options != undefined) {
			
			this.jwt = options.jwt;
			this.username = options.username;
		}			
	}

	getMessages = async () => {
		let result = await this.getFetchResult(messagesservice.unreadedMessagesEndPoint + "/" + this.username);
		return result;
	}

	setMessages = async (messages) => {
		let requestBody = JSON.stringify(messages);

		let postResult = await this.postFetchResult(messagesservice.setMessagesEndPoint, requestBody);
		return postResult;
	}

	getUnreadedMessagesCount = async (username) => {
		let result = await this.getFetchResult(messagesservice.unreadedMessagesCountEndPoint + "/" + username);
		return result;
	}


}