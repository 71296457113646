<template>
    <h1>Настройки</h1>
    <span v-if="!isErrorOrNotLogged">
        |<router-link to="/admin"> Сменить пароль </router-link>|
        <router-link to="/admin/Styles"><span> Интерфейс и стили </span></router-link>|
        <span v-if="isAdmin">
            <router-link to="/admin/Users"> Список пользователей </router-link>|
            <router-link to="/admin/Baselayers"> Базовые карты </router-link>|
            <router-link to="/admin/VectorLayers"> Список слоёв </router-link>|
            <router-link to="/admin/Translations"> Псевдонимы полей </router-link>|
            <router-link to="/admin/Ignorations"> Скрытые поля </router-link>|
            <router-link to="/admin/MessagesAdmin"> Сообщения (Что нового) </router-link>|
        </span>
        <router-view />
    </span>

    <ErrorMessage :messagevalue="message" v-else></ErrorMessage>
</template>

<script>
    import { onMounted, ref } from 'vue';
    import { loginservice } from '../services/loginservice';
    import ErrorMessage from './Shared/ErrorMessage.vue';

    export default {
        name: "Admin",
        setup() {
            const message = ref('AuthNeeded');
            const isErrorOrNotLogged = ref(false);
            const isAdmin = ref(false);
            onMounted(async () => {
                try {
                    let logonResult = await loginservice.checkLogin();
                    isErrorOrNotLogged.value = !logonResult.result;
                    if (logonResult.credits.userrole == "admin") {
                        isAdmin.value = true;
                    }
                    //console.log(logonResult);
                }
                catch (e) {
                    message.value = "catch on Admin.vue: " + e;
                    isErrorOrNotLogged.value = true;
                }
            });
            return {
                isErrorOrNotLogged,
                isAdmin,
                message
            }
        },
        components: {
            ErrorMessage
        }
    }
</script>
