<template>
    <div v-if="isAdmin">
        <h2>Сообщения (Что нового)</h2>
        <div v-if="!isSaved">
            <div class="container">
                <table class="table">
                    <thead class="thead-dark">
                        <tr>
                            <th scope="col" class="col-1">#</th>
                            <th scope="col" class="col-2">Заголовок</th>
                            <th scope="col" class="col-2">Дата</th>
                            <th scope="col" class="col-4">Содержание</th>
                            <th scope="col" class="col-1">Просмотрено</th>
                            <th scope="col" class="col-1">Удалить</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(message, index) in messagesList" :key="index">
                            <td class="col-1" scope="row">{{index+1}}</td>
                            <td class="col-2">
                                <input class="form-control" type="text" v-model="message['MessageTitle']" />
                            </td>
                            <td class="col-2">
                                <input class="form-control" type="text" v-model="message['MessageDate']" />
                            </td>
                            <td class="col-4">
                                <div v-for="(bodyX, index) in message['MessageBody']" :key="index">
                                    <input class="form-control" type="text" v-model="message['MessageBody'][index]" />
                                </div>

                                <button class="btn btn-dark" @click="addNewSubmessage(message)">+</button>
                            </td>
                            <td class="col-1">
                                <div v-for="(bodyX, index) in message['ViewedBy']" :key="index">
                                    <input class="form-control" type="text" v-model="message['ViewedBy'][index]" />
                                </div>

                                
                            </td>
                            <td class="col-1"><button @click="removeItem(message)" class="btn btn-dark disabled" aria-hidden="true"><span class="fa fa-recycle"></span></button></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <button class="btn btn-dark" @click="saveBtn">Сохранить</button>
            <button class="btn btn-dark ml-1" @click="addNewMessage">Добавить сообщение</button>
        </div>
        <div v-else>
            <SavedMessage />
        </div>
    </div>
    <div v-else>
        <ErrorMessage messagevalue="Нужны права администратора"></ErrorMessage>
    </div>
    <p>

    </p>
</template>
<script>
    import {  onMounted, computed,ref } from 'vue';
    import { useStore } from 'vuex';
    import ErrorMessage from '../Shared/ErrorMessage.vue';
    import SavedMessage from '../Shared/SavedMessage.vue';
    import { loginservice } from '../../services/loginservice';
import { messagesservice } from '../../services/messagesservice';
    export default {
        name: 'MessagesAdmin',
        setup() {
            const store = useStore();
            const isAdmin = computed(() => (store.state.isAdmin));
            const isSaved = ref(false);

            const messagesList = ref([]);

            let messagesService = null;

            onMounted(async () => {
                let logonResult = await loginservice.checkLogin();
                //Check Auth
                if (logonResult.result === true) {
                    let options = {
                        jwt: logonResult.credits.jwt,
                        username: logonResult.credits.username
                    }

                    messagesService = new messagesservice(options);
                    let messages = await messagesService.getMessages();


                    messagesList.value = messages;
                }
            });

            const removeItem = async (message) => {
                let itemIndex = messagesList.value.findIndex(o => o == message);
                if (itemIndex > -1) {
                    messagesList.value.splice(itemIndex, 1);
                    return;
                }
                console.log("Error! Unable to removeItem with name: " + message);
            }

            const addNewSubmessage = async (message) => {
                try {
                    let submessage = "текст сообщения";
                    let itemIndex = messagesList.value.findIndex(o => o == message);
                    if (itemIndex > -1) {
                        messagesList.value[itemIndex].MessageBody.push(submessage);
                    }                    
                }
                catch {
                    console.log("addNewSubmessage error");
                }
            }

            const addNewMessage = async () => {
                try {
                    let newMessage = {
                        MessageTitle: 'заголовок сообщения',
                        MessageBody: ["текст сообщения"],
                        ViewedBy: ["admin"],
                    };
                    messagesList.value.push(newMessage);
                }
                catch {
                    console.log("addNewMessage error");
                }
            }

            const saveBtn = async () => {
                let saveResult = await messagesService.setMessages(messagesList.value);

                if (saveResult) {
                    isSaved.value = true;
                }
                else {
                    console.log("and error occured when save messages list");
                }
            }

            return {
                isAdmin,
                isSaved,
                saveBtn,
                addNewSubmessage,
                addNewMessage,
                removeItem,
                messagesList
            }
        },
        components: {
            ErrorMessage,
            SavedMessage
        }
    }
</script>
