export class fetchservice {

    static errorResult = "error";

	constructor(options) {
		
		this.noauth = true;
		if (options != undefined) {
			this.jwt = options.jwt;
			this.noauth = false;
        }		
    }

	getFetchResult = async (url) => {

		let fetchResult = this.noauth ? await this.fetchNotAuth(url) : await this.fetchWithAuth(url);
		return fetchResult;	
	}


	postFetchResult = async (url, requestBody) => {
		let postResult = await this.postWithAuth(url, requestBody);
		return postResult
	}
	
	postWithAuth = async (url, requestBody) => {
		let fetchResult = await fetch(url, {
			method: 'POST',
			body: requestBody,
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.jwt
			},
			credentials: 'include',
		});
		if (fetchResult.status == 200) {
			return true;
		}
		else {
			return fetchservice.errorResult;
		}


	}

	fetchNotAuth = async (url) => {

		let fetchResult = await fetch(url, {
			headers: {
				'Content-Type': 'application/json'
			}
		});
		
		if (fetchResult.status == 200) {
			let urlResult = await fetchResult.json();
			return urlResult;		
		}
		else {
			return fetchservice.errorResult;
		}
	}


	fetchWithAuth = async (url) => {
		let fetchResult = await fetch(url, {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + this.jwt
			},
			credentials: 'include'
		});
		if (fetchResult.status == 200) {
			let urlResult = await fetchResult.json();
			return urlResult;
		}
		else {
			return fetchservice.errorResult;
		}
    }
}