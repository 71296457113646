import { createWebHistory, createRouter } from "vue-router";
import Login from "@/components/Login.vue";
import Mapview from "@/components/Mapview.vue";
import Admin from "@/components/Admin.vue";
import Messages from "@/components/Messages.vue";
import UserInfo from "@/components/Admin/UserInfo.vue";
import Users from "@/components/Admin/Users.vue";
import VectorLayers from "@/components/Admin/VectorLayers.vue";
import Baselayers from "@/components/Admin/Baselayers.vue";
import Translations from "@/components/Admin/Translations.vue";
import Ignorations from "@/components/Admin/Ignorations.vue";
import Styles from "@/components/Admin/Styles.vue";
import MessagesAdmin from "@/components/Admin/MessagesAdmin.vue";

const routes = [
    {
        path: "/login",
        name: "Login",
        component: Login,
    },
    {
        path: "/messages",
        name: "Messages",
        component: Messages,
    },
    {
        path: "/admin",
        name: "Admin",
        component: Admin,
        children: [
            {
                path: '',
                component: UserInfo
            },
            {
                path: 'users',
                component: Users
            },
            {
                path: 'baselayers',
                component: Baselayers
            },
            {
                path: 'vectorlayers',
                component: VectorLayers
            },
            {
                path: 'translations',
                component: Translations
            },
            {
                path: 'ignorations',
                component: Ignorations
            },
            {
                path: 'styles',
                component: Styles
            },
            {
                path: 'messagesadmin',
                component: MessagesAdmin
            }
        ]
    },
    {
        path: "/",
        name: "Mapview",
        component: Mapview,
    }
    /*
    ,
    { path: '/mapview', redirect: '/' }
    */
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

/*page title by component name 
 
router.beforeEach((to, from, next) => {
    document.title = to.name;
    next();
});
*/

export default router;