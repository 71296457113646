<template>
    <div v-if="!input.isCompleted">
        <form class="form-signin" @submit.prevent="changepwd">
            <div class="form-control text-center" >
                <label>Старый пароль</label>
                <input required v-model="input.userpassword" type="password" placeholder="старый пароль" />
                <label>Новый пароль</label>
                <input required v-model="input.newpassword" type="password" placeholder="новый пароль" />

                <label>Новый пароль еще раз</label>
                <input required v-model="input.renewpassword" type="password" placeholder="новый пароль" />                    
            </div>                    
            <hr />                   
            <button type="submit">Сменить</button>
            
        </form>
    </div>
    <h3 v-else>{{input.messageResult}}</h3>
</template>
<script>
    import { reactive,computed } from 'vue';
    import { useStore } from 'vuex';
    export default {
        name: 'ChangePassword',
        setup() {
            const store = useStore();
            const input = reactive({
                username: computed(() => store.state.username),
                userpassword: '',
                newpassword: '',
                renewpassword: '',
                isCompleted: false,
                messageResult: "Default Message"
            });
            const changepwd = async () => {
                if (input.newpassword != input.renewpassword) {
                    input.isCompleted = true;
                    input.messageResult = "Ошибка! Новый пароль не совпадает";
                    return;
                }
                let requestBody = {
                    username: input.username,
                    userpassword: input.userpassword,
                    newpassword: input.newpassword
                };
                let response = await fetch('/api/login/updatepassword', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    credentials: 'include',
                    body: JSON.stringify(requestBody)
                });
                let responseStatus = await response.status;
                if (responseStatus == 401) {
                    input.messageResult = "Ошибка! Неверный пароль!";
                }
                if (responseStatus == 200) {
                    input.messageResult = "Пароль успешно изменен!";
                }
                if (responseStatus > 401) {
                    input.messageResult = "Ошибка! " + responseStatus;
                }
                input.isCompleted = true;
            }
            return {
                input,
                changepwd
            }
        }
    }
</script>
<style>
    .form-signin {
        width: 100%;
        max-width: 330px;
        padding: 15px;
        margin: auto;
    }
        .form-signin .checkbox {
            font-weight: 400;
        }
        .form-signin .form-control {
            position: relative;
            box-sizing: border-box;
            height: auto;
            padding: 10px;
            font-size: 16px;
        }
            .form-signin .form-control:focus {
                z-index: 2;
            }
        .form-signin input[type="email"] {
            margin-bottom: -1px;
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
        }
        .form-signin input[type="password"] {
            margin-bottom: 10px;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }
</style>
