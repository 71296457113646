<template>
    <div  class="container col-12">
        <h2>Интерфейс и стили</h2>
        <div v-if="!isSaved">
            <div>
                <hr />
                <h3>Стиль подсветки</h3>
                <!--
                [
                    {
                    UserName: string,
                    Style: {Style}
                    },
                    ..
                ]
                -->
                <table class="table">
                    <thead class="thead-dark">
                        <tr>
                            <th scope="col" class="col-1">#</th>
                            <th scope="col" class="col-2">Пользователь</th>
                            <th scope="col" class="col-8">Стиль подсветки</th>
                            <th scope="col" class="col-1">Remove</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(hStyle, index) in allHighliteStyles" :key="index">
                            <td class="col-1" scope="row">{{index+1}}</td>
                            <td class="col-2"><input class="form-control" type="text" v-model="hStyle['UserName']" /></td>
                            <td class="col-8"><input class="form-control" type="text" v-model="hStyle['StyleJson']" /></td>
                            <td class="col-1"><button @click="removeStyle(hStyle)" class="btn btn-dark disabled" aria-hidden="true"><span class="fa fa-recycle"></span></button></td>
                        </tr>
                    </tbody>
                </table>
                <button class="btn btn-dark" @click="saveStylesBtn">Сохранить</button>
                <button class="btn btn-dark ml-1" @click="addNewStyle">Добавить стиль</button>
            </div>
            <hr />
            <div >
                <h3>Развёрнутые группы слоёв</h3>
                <!--
                [
                    {
                    UserName: string,
                    GroupNames: [string]
                    },
                    ..
                ]
                -->
                <div class="row justify-content-center" >
                    <div class="col-10 ">
                        <table class="table">
                            <thead class="thead-dark">
                                <tr>
                                    <th scope="col" class="col-1">#</th>
                                    <th scope="col" class="col-3">Пользователь</th>
                                    <th scope="col" class="col-4">Группа слоёв</th>
                                    <th scope="col" class="col-1">Remove</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(uGroup, index) in allUncollapsedGroups" :key="index">
                                    <td class="col-1" scope="row">{{index+1}}</td>
                                    <td class="col-3"><input class="form-control" type="text" v-model="uGroup['user']" /></td>
                                    <td class="col-4"><input class="form-control" type="text" v-model="uGroup['group']" /></td>
                                    <td class="col-1"><button @click="removeItem(uGroup)" class="btn btn-dark disabled" aria-hidden="true"><span class="fa fa-recycle"></span></button></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <button class="btn btn-dark" @click="saveBtn">Сохранить</button>
                <button class="btn btn-dark ml-1" @click="addNewGroup">Добавить группу</button>
            </div>


        </div>
        <div v-else>
            <SavedMessage />
        </div>
    </div>

</template>

<script>
    import {  onMounted, computed,ref } from 'vue';
    import { useStore } from 'vuex';
    import SavedMessage from '../Shared/SavedMessage.vue';
    import { loginservice } from '../../services/loginservice';
    import { vectorlayerservice } from '../../services/vectorlayerservice';

    export default {
        name: 'Interface and styles',
        setup() {
            const store = useStore();
            const isAdmin = computed(() => (store.state.isAdmin));
            const isSaved = ref(false);            
            let vectorLayerService = null;
            let allUncollapsedGroups = ref([]);

            let allHighliteStyles = ref([]);

            onMounted(async () => {
                let logonResult = await loginservice.checkLogin();
                //Check Auth
                if (logonResult.result === true) {
                 
                    let options = {
                        jwt: logonResult.credits.jwt,
                        username: logonResult.credits.username
                    }
                    vectorLayerService = new vectorlayerservice(options);  

                    let fetchedValues = await vectorLayerService.getAllUncollapsedGroups();
                    //map uncollapsed groups
                    for (let item of fetchedValues) {
                        for (let group of item.GroupNames) {
                            let newItem = {
                                user: item.UserName,
                                group: group
                            };
                            allUncollapsedGroups.value.push(newItem);
                        }                        
                    }

                    let hStyles = await vectorLayerService.getAllHighliteStyles();
                    //MAP styles
                    for (let style of hStyles) {
                        style.StyleJson = JSON.stringify(style.Style);
                    }                    

                    allHighliteStyles.value = hStyles;
                }
            });

            const saveBtn = async () => {   
                //unMAP
                let resultArray = [];
                for (let item of allUncollapsedGroups.value) {
                    let possibleGroup = item.group;
                    let possibleUser = item.user;
                    let findedIndex = resultArray.findIndex(o => o.UserName == possibleUser);
                    if (findedIndex> -1) {
                        resultArray[findedIndex].GroupNames.push(possibleGroup);
                    }
                    else {
                        resultArray.push({
                            UserName: possibleUser,
                            GroupNames: [possibleGroup]
                        })
                    }
                }
                let saveResult = await vectorLayerService.setAllUncollapsedGroups(resultArray);
                if (saveResult) {
                    isSaved.value = true;
                }
                else {
                    console.log("and error occured when save allUncollapsedGroups list");
                } 
            }

            const addNewGroup = async () => {
                let newItem = {
                    user: store.state.username,
                    group: "GROUP_NAME"
                };
                allUncollapsedGroups.value.push(newItem);
            }

            const removeItem = async (removedGroup) => {               
                let groupIndex = allUncollapsedGroups.value.findIndex(o => o.group == removedGroup.group && o.user == removedGroup.user);
                if (groupIndex > -1) {
                    allUncollapsedGroups.value.splice(groupIndex, 1);
                    return;
                }
                console.log("Error! Unable to remove group with name: " + removedGroup.group);
            }

            const addNewStyle = async () => {
                let newStyle = vectorLayerService.getDefaultHighliteStyle();
                allHighliteStyles.value.push({
                    UserName: store.state.username,
                    Style: newStyle,
                    StyleJson: JSON.stringify(newStyle)
                });
            }

            const saveStylesBtn = async () => {                                
                let saveResult = await vectorLayerService.setAllHighliteStyles(allHighliteStyles.value);
                if (saveResult) {
                    isSaved.value = true;
                }
                else {
                    console.log("and error occured when save allHighliteStyles list");
                } 
            }

            const removeStyle = async (removedStyle) => {
                let styleIndex = allHighliteStyles.value.findIndex(o => o.StyleJson == removedStyle.StyleJson && o.UserName == removedStyle.UserName);
                if (styleIndex > -1) {
                    allHighliteStyles.value.splice(styleIndex, 1);
                    return;
                }
                console.log("Error! Unable to remove style with name: " + removedStyle.Style.name);
            }

            return {
                isAdmin,
                isSaved,
                saveBtn,
                saveStylesBtn,
                removeItem,
                removeStyle,
                addNewGroup,
                addNewStyle,
                allUncollapsedGroups,
                allHighliteStyles
            }
        },

        components: {
            SavedMessage
        }
    }
</script>