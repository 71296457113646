import {createStore } from 'vuex';

export default createStore({
    state: {
        authenticated: false,
        isAdmin: false,
        username: "unknown",
        userrole: "unknown",
        jwt: "",
        hasunreadedmessages: false,
        unreadedmessagescount: 0
    },
    mutations: {
        SET_AUTH: (state, userAuthInfo) => {
            state.authenticated = userAuthInfo.authenticated;
            state.isAdmin = userAuthInfo.isAdmin;
            state.username = userAuthInfo.username;
            state.userrole = userAuthInfo.userrole;
            state.jwt = userAuthInfo.jwt;
            state.hasunreadedmessages = userAuthInfo.hasunreadedmessages;
            state.unreadedmessagescount = userAuthInfo.unreadedmessagescount;
        }
    },
    actions: {
        setAuth: ({ commit }, userAuthInfo) => commit('SET_AUTH', userAuthInfo),
    },
    modules: {}
})
