//import * as L from 'leaflet';
import { StaticStyler, TypedStyler } from '../js/vgisstyler';
import { fetchservice } from './fetchservice';

export class vectorlayerservice extends fetchservice {

	static defaultUrl =    "/api/vectorlayer";
	static getUrlUrl =     "/api/vectorlayer/GetUrl";
	static getStylingUrl = "/api/vectorlayer/GetStyling";
	static getLevelsUrl =  "/api/vectorlayer/getlevels";

	static getAllLevelsUrl = "/api/vectorlayer/getalllevels";
	static setAllLevelsUrl = "/api/vectorlayer/setalllevels";

	static getHighliteStyleUrl =  "/api/vectorlayer/GetHighliteStyle";
	static AllHighliteStylesUrl = "/api/vectorlayer/AllHighliteStyles";

	static getTypedStyleUrl  = "/api/vectorlayer/GetTypedStyle/";
	static getStaticStyleUrl = "/api/vectorlayer/GetStaticStyle/";

	static getUncollapsedGroupsUrl = "/api/vectorlayer/GetUncollapsedGroups";
	static AllUncollapsedGroupsUrl = "/api/vectorlayer/AllUncollapsedGroups";

	//options {
	// jwt - bearer token
	// url - api url
	// }
	constructor(options) {
		super(options);
		this.jwt = options.jwt;
		this.username = options.username;
    }

	getUrl = async () => {		
		let fetchResult = await this.getFetchResult(vectorlayerservice.getUrlUrl);
		return fetchResult.url;	
	}

	getLevels = async () => {
		let fetchResult = await this.getFetchResult(vectorlayerservice.getLevelsUrl);
		return fetchResult;	
	}

	getAllLevels = async () => {
		let fetchResult = await this.getFetchResult(vectorlayerservice.getAllLevelsUrl);
		return fetchResult;	
	}

	addLevel = () => {
		return {
			groupname: "NEWGROUP",
            levelicon: "x",
            levelname: "NEWLEVEL",
              visible: false,
			belongsFilterOptions: {}
        }
    }

	setAllLevels = async (levels) => {
		let requestBody = JSON.stringify(levels);
		let postResult = await this.postFetchResult(vectorlayerservice.setAllLevelsUrl, requestBody);
		return postResult;
    }

	getStyling = async () => {		
		let vtStyling = {};
		let vectorTileStylingNew = await this.getFetchResult(vectorlayerservice.getStylingUrl);
		for (var propt in vectorTileStylingNew) {
			let proptValue = vectorTileStylingNew[propt];

			//proptValue =
			//	<fieldName>,<styleName> - TypedStyle
			//  <styleName> - StaticStyle
			var chunks = proptValue.split(",");
			if (chunks.length == 1) {
				//STATIC STYLE
				let styleName = chunks[0];
				let url = vectorlayerservice.getStaticStyleUrl + styleName;
				let style = await this.getFetchResult(url);
				vtStyling[propt] = (new StaticStyler(style)).GetStyle;
			}
			else {
				//TYPED STYLE
				let styleName = chunks[1];
				let fieldName = chunks[0];
				let url = vectorlayerservice.getTypedStyleUrl + styleName;
				let styles = await this.getFetchResult(url);
				vtStyling[propt] = (new TypedStyler(fieldName, styles)).GetStyle;
            }
		}     
		return vtStyling;
	}

	getUncollapsedGroups = async () => {
		return await this.getFetchResult(vectorlayerservice.getUncollapsedGroupsUrl + "?userName=" + this.username);
    }


	getAllUncollapsedGroups = async () => {
		return await this.getFetchResult(vectorlayerservice.AllUncollapsedGroupsUrl);
	}

	setAllUncollapsedGroups = async (groups) => {
		let requestBody = JSON.stringify(groups);
		let postResult = await this.postFetchResult(vectorlayerservice.AllUncollapsedGroupsUrl, requestBody);
		return postResult;
	}

	getHighliteStyle = async () => {
		return await this.getFetchResult(vectorlayerservice.getHighliteStyleUrl);
	}

	getAllHighliteStyles = async () => {
		return await this.getFetchResult(vectorlayerservice.AllHighliteStylesUrl);
	}

	setAllHighliteStyles = async (highliteStyles) => {
		let requestBody = JSON.stringify(highliteStyles);
		let postResult = await this.postFetchResult(vectorlayerservice.AllHighliteStylesUrl, requestBody);
		return postResult;
	}
	getDefaultHighliteStyle = () => {
		return {
			name: "highlitestyle",
			fill: true,
			weight: 3,
			fillColor: "#ff0000",
			color: "#ff00ff",
			fillOpacity: 0.2,
			opacity: 0.9
        }		
	}

}